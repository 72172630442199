@use "node_modules/@akelius/material-theme/src/style/utils";
@import "../../scss/base/variables";
@import "node_modules/@akelius/material-theme/src/functions/grid-widths.function";
@import "node_modules/@akelius/material-theme/src/style/breakpoints";
@import "node_modules/@akelius/material-theme/src/style/sizes";

#{utils.raiseSpecificity(".cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.xl-dialog-content")} {
  width: gridWidth(12, "xs");
  max-width: 100% !important;

  @media (min-width: map-get($grid-breakpoints, "md")) {
    width: gridWidth(12, "md");
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    width: gridWidth(12, "lg");
  }

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    width: gridWidth(12, "xl");
  }

  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    width: gridWidth(12, "xl");
  }
}

.big-dialog-content {
  width: gridWidth(12, "xs");

  @media (min-width: map-get($grid-breakpoints, "md")) {
    width: gridWidth(10, "md");
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    width: gridWidth(8, "lg");
  }

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    width: gridWidth(8, "xl");
  }

  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    width: gridWidth(8, "xl");
  }
}

.standard-dialog-content {
  width: gridWidth(12, "xs");

  @media (min-width: map-get($grid-breakpoints, "md")) {
    width: gridWidth(8, "md");
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    width: gridWidth(6, "lg");
  }

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    width: gridWidth(6, "xl");
  }

  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    width: gridWidth(6, "xxl");
  }
}

.small-dialog-content,
.notification-dialog-content {
  width: gridWidth(12, "xs");

  @media (min-width: map-get($grid-breakpoints, "md")) {
    width: gridWidth(6, "md");
  }

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    width: gridWidth(4, "lg");
  }

  @media (min-width: map-get($grid-breakpoints, "xl")) {
    width: gridWidth(4, "xl");
  }

  @media (min-width: map-get($grid-breakpoints, "xxl")) {
    width: gridWidth(4, "xxl");
  }
}
