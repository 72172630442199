.text-right {
  text-align: right;
  justify-content: flex-end;
}

.text-left {
  text-align: left;
  justify-content: flex-start;
}

.is-hidden {
  display: none;
}

.is-visibility-hidden {
  visibility: hidden;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.position-left {
  left: 0;
}

.position-right {
  right: 0;
}

.is-block {
  display: block;
}

.cf:after {
  content: "";
  clear: both;
  display: table;
}

.relative {
  position: relative;
}

.icon-size-22 {
  font-size: 22px;
}

.title-icon {
  float: left;
  width: 24px;
  margin-right: 5px;
}
