@use "@akelius/material-theme/style/sizes";

.action-bar-component {
  display: flex;
  justify-content: flex-start;
  padding: sizes.$small-plus 0 0 0;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  &.right-aligned {
    justify-content: flex-end;
  }

  & > * {
    margin: 0 sizes.$small sizes.$small 0 !important; // important to override material styles
    flex: 0 0 auto;

    &.right {
      margin: 0 sizes.$medium 0 auto !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }

  &.bottom-aligned > * {
    margin-bottom: 0 !important;
  }
}
