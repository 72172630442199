@use "@akelius/material-theme/style/theme.scss";
@use "@akelius/material-theme/src/style/sizes";

@import "../mixins/user-select";

mat-icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.is-hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.hyphen-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.break-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.border-top {
  border-top: 1px solid theme.$line-color;
}

.border-bottom {
  border-bottom: 1px solid theme.$line-color;
}

.cursor--pointer {
  cursor: pointer;
}

.property-picture__cell .wrp {
  height: 278px !important; // need to use it, cause there is another style with .wrp[attribute], attr come from angular and we need to override it
  margin-top: -1px;
  position: relative;
}

.checkbox__checked {
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
}

.checkbox__label {
  font-size: 20px;
  vertical-align: middle;
}

.text--bold {
  font-weight: 700;
}

.text--normal {
  font-weight: 400;
}

.hidden {
  display: none;
}

.image-fallback {
  position: relative;
  overflow: hidden;

  &::before {
    background-color: theme.$background;
    color: theme.$disabled-color;
    text-align: center;
    content: "error_outline";
    font-size: 24px;
    height: 100%;
    padding-top: calc(33.3333% - 12px); // we are assuming an image aspect ratio of 3:2 (66.666% of width)
    width: 100%;
    position: absolute;
  }
}

// Overrides for ak-card to handle before after pictures
.before-image .ak-card-image-container {
  border-right: 1px solid theme.$line-color;
}

.after-image .ak-card-image-container {
  border-left: 1px solid theme.$line-color;
}

.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.column-gap {
  column-gap: sizes.$medium-plus;
}

.active-button {
  background-color: theme.$primary-color !important;
  color: theme.$primary-text-color !important;
}

.ak-clean-your-text-column-width {
  // according to clean your text
  // use a column width of up to fifty signs
  // usage of 50ch does not work properly
  // 420px like the website
  max-width: 420px;
}
