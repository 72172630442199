@import "node_modules/@akelius/material-theme/src/style/sizes";

// used for selection tree
.tree-checkbox {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-inner-container {
    margin-top: 2px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-label {
    line-height: $line-height !important;
  }
}
