@use "node_modules/@akelius/material-theme/src/style/sizes";

@import "normalize.css";

@import "scss/base/variables";
@import "scss/base/helper";
@import "scss/base/main";
@import "scss/base/formly";
@import "scss/mixins/clearfix";
@import "scss/mixins/appearance";
@import "scss/mixins/transform";
@import "scss/mixins/rotate";
@import "scss/base/_bootstrap-grid";
@import "scss/mixins/user-select";
@import "scss/base/typography";
@import "scss/base/modal";
@import "scss/base/cards";
@import "scss/base/popup-content";
@import "scss/base/data-tables";
@import "scss/base/action-bar";
@import "scss/base/filters";
@import "scss/base/tree";

@import "node_modules/@akelius/material-theme/src/style/typography";
@import "node_modules/@akelius/material-theme/src/style/utils";

:root {
  --app-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

// Sentry MUST come above all dialogs so we give it a crazy big z
.sentry-error-embed-wrapper {
  z-index: 10001 !important;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  > img {
    max-width: 60%;
  }
}

@include media-breakpoint-up(sm) {
  .img-container {
    > img {
      width: 24%;
    }
  }
}

formly-form {
  padding-top: sizes.$small;
}
