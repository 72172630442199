//
// These variables are exported in the public theme
//

// main branding colour, primary theme for material, used to indicate selected status
$primary-color: var(--primary-color);
// color to be used for text shown on primary color
$primary-text-color: var(--primary-text-color);

// font-family to be used for theme
$font-family: var(--font-family);
// standard text colour
$font-standard-color: var(--font-standard-color);
// standard text size
$font-standard-size: var(--font-standard-size);
// standard text weight
$font-standard-weight: var(--font-standard-weight);

// color for links
$font-link-color: var(--font-link-color);
// font-weight for links
$font-link-weight: var(--font-link-weight);
// text decoration for links
$link-text-decoration: var(--link-text-decoration);
// font color for hovered links
$font-link-hover-color: var(--font-link-hover-color);

// semantic colors
$status-warning-color: var(--status-warning-color);
$status-success-color: var(--status-success-color);
$status-error-color: var(--status-error-color);
$status-info-color: var(--status-info-color);
// color to be used for text shown on info status
$status-info-text-color: var(--status-info-text-color);

// color to be used for disabled fields
$disabled-color: var(--disabled-color);
// color to be used for borders
$line-color: var(--line-color);

// background color for the app
$background: var(--background);

// color for the ripple effect - needed for hover effects
$ripple-color: var(--ripple-color);

// used to allow some colors which would be primary in material, to be controlled individually
// used for form field underlines, application name, etc
$accent-color: var(--accent-color);
// color for text shown over accent color
$accent-text-color: var(--accent-text-color);

//
// These variables are purely for the private theme
//

$primary-hover-color: var(--primary-hover-color);
$primary-hover-text-color: var(--primary-hover-text-color);

$font-secondary-text-color: var(--font-secondary-text-color);

$advert-background: var(--advert-background);
$advert-text: var(--advert-text);

$primary-button-border-color: var(--primary-button-border-color);
$primary-button-border-hover-color: var(--primary-button-border-hover-color);

$button-standard-background-color: var(--button-standard-background-color);
$button-standard-text-color: var(--button-standard-text-color);
$button-standard-border-color: var(--button-standard-border-color);

$button-standard-hover-background-color: var(--button-standard-hover-background-color);
$button-standard-hover-text-color: var(--button-standard-hover-text-color);
$button-standard-hover-border-color: var(--button-standard-hover-border-color);

$button-border-radius: var(--button-border-radius);
$button-horizontal-padding: var(--button-horizontal-padding);

$icon-font-color: var(--icon-font-color);

$header-icon-color: var(--header-icon-color);

$slide-accent-color: var(--slide-accent-color);

$table-row-hover: var(--table-row-hover);

$tooltip-container-color: var(--tooltip-container-color);

$text-color-on-primary-background: var(--text-color-on-primary-background);
