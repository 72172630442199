@use 'breakpoints';
@use 'sizes';
@use 'private-theme' as theme;
@use 'sass:map';

:root,
:host {
  --mdc-theme-text-primary-on-background: #{theme.$font-standard-color};
}

body {
  font-size: sizes.$font-size;
  line-height: sizes.$line-height;
  font-family: theme.$font-family;
  color: theme.$font-standard-color;
  font-weight: theme.$font-standard-weight;
}

h1,
h2,
h3,
h4 {
  &.top-aligned {
    margin-top: 0;
  }
}

h1 {
  font-family: theme.$font-family;
  margin: sizes.$medium-plus 0 sizes.$small-plus;
  font-size: 24px;
  line-height: 32px;
  color: theme.$accent-color;
  font-weight: theme.$font-standard-weight;

  @media (min-width: map.get(breakpoints.$grid-breakpoints, 'md')) {
    font-size: 32px;
    line-height: 40px;
  }
}

h2 {
  font-family: theme.$font-family;
  font-weight: theme.$font-standard-weight;
  font-size: 24px;
  color: theme.$font-standard-color;
  line-height: 32px;
  margin: sizes.$medium-plus 0 sizes.$small-plus;
}

h3 {
  font-family: theme.$font-family;
  font-weight: theme.$font-standard-weight;
  font-size: 20px;
  color: theme.$font-standard-color;
  line-height: sizes.$line-height;
  margin: sizes.$small-plus 0 sizes.$small;
  a {
    font-size: 20px;
    font-weight: normal;
  }
}

h4 {
  font-family: theme.$font-family;
  font-size: sizes.$font-size;
  color: theme.$font-standard-color;
  font-weight: 700;
  line-height: 20px;
  margin: sizes.$small-plus 0 sizes.$small;
}

p {
  font-family: theme.$font-family;
  font-size: sizes.$font-size;
  color: theme.$font-standard-color;
  line-height: sizes.$line-height;
  margin: sizes.$small-plus 0;
  margin-block-start: 0;
}

a {
  font-family: theme.$font-family;
  font-size: 16px;
  font-weight: theme.$font-link-weight;
  color: theme.$accent-color;
  line-height: 24px;
  margin: 4px 0 8px;

  text-decoration: theme.$link-text-decoration;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    color: theme.$font-link-hover-color;
  }
}

ul li,
ol li {
  margin-bottom: sizes.$small;
}
