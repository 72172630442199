//noinspection CssUnknownTarget
body {
  padding-bottom: 40px;
  word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
