@use "@akelius/material-theme/style/theme.scss";
@use "@akelius/material-theme/src/style/sizes";
@import "../../scss/base/variables";

.mat-data-table {
  // this style overrides the material-theme. There is still no agreement on whether this hover should be there
  tr.mat-mdc-header-row:hover th {
    background-color: white !important;
  }

  // variant that prevents text wrapping onto new lines for smaller rows
  &.dense th,
  &.dense td {
    white-space: nowrap;
  }

  tr.detail-row {
    & td,
    & th {
      background-color: white !important;
    }
  }

  tr.mat-mdc-footer-row {
    font-weight: bold;
  }

  .icon-column {
    max-width: 80px !important;
    min-width: 80px !important;
  }

  .data-table-date {
    white-space: nowrap;
  }

  & table {
    width: 100%;
    display: table;
  }

  & td,
  & th {
    padding: 16px !important;
    display: table-cell;
    overflow: hidden;
    vertical-align: top;

    // height and min height are needed to allow child divs to take the full height of the cell
    height: 1px;
    min-height: 1px;

    @media (max-width: $screen-sm-min) {
      padding: 8px !important;
    }

    a {
      margin: 0;
    }

    &.expansion-content {
      padding: 0 !important;
    }

    &.content-cell {
      min-width: 200px;
    }

    &.button-cell,
    &.select-cell,
    &.expand-row-cell,
    &.expand-all-cell {
      width: 58px !important;
      min-width: 58px !important;
      padding: sizes.$extra-small !important;
    }

    &.small {
      width: 150px;
      min-width: 150px;
    }

    &.medium {
      width: 200px;
      min-width: 200px;
    }

    &.large {
      width: 350px;
      min-width: 350px;
    }
  }

  & .detail-row td {
    padding: 0 !important;
  }

  th.right-aligned {
    padding-right: 24px !important;

    & div {
      justify-content: flex-end;
    }
  }

  td.right-aligned {
    text-align: right;
    padding-right: 24px !important;
  }

  .mat-sort-header-content {
    text-align: left !important;
  }

  tbody tr:last-child {
    td {
      border-bottom: none;
    }
  }

  .no-results-found:hover {
    background-color: white;
  }

  .table-flex-display {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .table-flex-cell {
    width: 200px;
    padding: 8px 16px 8px 8px;
  }

  .auto-width-col {
    min-width: 0 !important;
  }

  .no-horizontal-padding-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .confirm-or-cancel-cell {
    width: 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;

    display: flex;
    justify-content: space-evenly;
  }
}
