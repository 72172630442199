@import "./variables";

$_opacity: 0.4;
$_gutter-s: 8px;
$_gutter-m: 16px;
$_constant-border: 1px solid #cecece;

.modal {
  background-color: rgba(#000000, $_opacity);
  bottom: 0;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: auto;
  padding: 0 $_gutter-m;
  position: fixed;
  right: 0;

  top: 0;
  z-index: 10000; /* Adjust according to your needs */

  &.fade {
    opacity: 0;
    will-change: opacity;

    .modal-content {
      transform: translate(0, -25%);
      will-change: transform;
    }
  }

  &.in {
    .modal-content {
      transform: translate(0, 0);
    }
  }

  &.fade.in {
    opacity: 1;
  }
}

@media (max-width: $screen-sm-min) {
  .modal-content {
    bottom: 0;
  }
}

.modal-open,
.lightbox-open {
  overflow: hidden;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  width: 100%;
  will-change: opacity;
}

.modal-content__message {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.modal-content__body {
  padding: $_gutter-m;
}

.mat-panel-title {
  .close-button {
    margin-left: auto;
  }
}

.modal-actions-button-left {
  margin-right: auto !important;
  margin-left: 0 !important;
}
