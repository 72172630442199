@use "@akelius/material-theme/src/style/sizes";

.form-block {
  margin: 0 0 48px 0 !important;
}

.hide-field {
  visibility: hidden;
  position: absolute;
}

.has-help-icon .help-icon {
  padding-right: sizes.$extra-small;
}

.form-inline-button {
  padding-top: 7px;
}

.mat-mdc-select-panel .mat-mdc-option {
  white-space: normal;
}

// overriding ak-theme for FormlyFileComponent
.mat-mdc-form-field-type-file {
  .mdc-text-field {
    padding: 0;
  }

  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
  }

  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}
