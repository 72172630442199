html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1920px,
);

$container-max-widths: ();
$enable-grid-classes: true;
$grid-columns: 12;
$grid-gutter-width: 16px;
$max-content-width: 1536px;

@import "node_modules/bootstrap/scss/mixins/grid-framework";
@import "node_modules/bootstrap/scss/mixins/breakpoints";
@import "node_modules/bootstrap/scss/mixins/grid";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities/display";
@import "node_modules/bootstrap/scss/utilities/flex";

.container {
  overflow: hidden;

  @include media-breakpoint-only(xl) {
    max-width: 80%;
  }

  @include media-breakpoint-up(xxl) {
    max-width: $max-content-width;
  }
}
