$extra-small: 4px;
$small: 8px;
$small-plus: 12px;
$medium: 16px;
$medium-plus: 24px;
$large: 32px;
$large-plus: 40px;
$extra-large: 48px;

$line-height: 24px;
$font-size: 16px;
