@use "sass:math";
@use '@akelius/material-theme/style/theme.scss';

.ak-card {
  border-radius: 3px;
  border: 1px solid theme.$line-color;
  height: 100%;
  width: 100%;
}

.ak-card-headline {
  margin-top: 0;
}

.ak-card-header {
  height: 0;
  width: 100%;
  //  image proportion is 3:2 and we use padding to calculate the height from the width.
  //  we add the 1 to allow for rounding problems since it's more desirable to risk a tiny amount of cropping
  //  than for 3:2 images to be contained and have white borders
  padding-bottom: (math.div(2, 3) * 100%) + 1;
  position: relative;
}

.ak-card-image-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid theme.$line-color;
}

.ak-card-image {
  object-fit: contain;
  height: 100%;
  width: 100%;

  &.image-cover {
    object-fit: cover;
  }
}

.ak-card-content {
  padding: 20px 12px;
}

.ak-card-key-fact {
  font-weight: bold;
}
