@use "@akelius/material-theme/src/style/sizes";
@use "@akelius/material-theme/style/theme.scss";

@import "variables";
@import "../../scss/mixins/_mat-spinner";

// These styles allow the addition of the select all option in the dropdowns
.filter-option {
  cursor: pointer !important;
  opacity: 1 !important;
  border-bottom: 1px solid theme.$line-color;

  &:hover {
    background-color: inherit !important;
  }
}

.filter-option > .mat-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-form-field,
.mat-form-field-wrapper {
  width: 100%;
}

mat-spinner {
  @include mat-spinner;
}

// this is required because bootstrap grid expects everything to use border box (by default expansion uses content-box)
// and the grid does not work inside the expansion
.mat-expansion-panel {
  box-sizing: border-box !important;
}

// if we want the height of the page to be fixed (no scrolling on window, then we have to give it a height here
// we use 100% not 100vh to allow the correct height on mobile
.mat-sidenav-container.fixed-height {
  height: 100%;
}

mat-form-field {
  margin-bottom: sizes.$extra-small;
}

@media (max-width: $screen-sm-min) {
  .mat-mdc-tab-body-content {
    padding-bottom: sizes.$small !important;
  }
}

// this lets us remove the outline on a mat form field
// we need this for the file picker
.field-without-outline .mat-mdc-form-field,
.mat-mdc-form-field.field-without-outline {
  // override all outline states with red
  --mdc-outlined-text-field-outline-width: 0;
  --mdc-outlined-text-field-focus-outline-width: 0;
}

// this fixes the alignment of chips when displayed in the table
mat-data-table mat-chip-set {
  margin-top: -8px;
}

// on mobile the actions are scrollable. So we do not need the space for the actions.
@media (max-width: $screen-sm-min) {
  .mdc-dialog__actions {
    min-height: 0 !important;
  }
}
