@use "@akelius/material-theme/src/style/sizes";
@import "./variables";

// These styles are used by the old filter components and should be removed
// once all

.filter-input {
  width: 280px;

  @media (max-width: $screen-sm-min) {
    width: 100%;
  }
}

.auto-width {
  width: auto;
}

.filter-checkbox {
  margin-top: sizes.$small;
}

// end of legacy filter styles

.standard-filter-button-container {
  min-width: 270px;

  .clear-button {
    display: flex;
    align-items: center;

    button {
      margin: sizes.$small 0;
    }

    @media (max-width: $screen-sm-min) {
      margin-top: sizes.$medium-plus;
    }
  }

  @media (max-width: $screen-sm-min) {
    width: 100%;
  }
}
